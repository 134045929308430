.breadcrumb {
  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    position: relative;
    margin: 0;
  }

  &__separator {
    font-size: 14px;
    user-select: none;
    margin: 0 8px;

    .icon {
      margin: 0 -4px;
    }
  }

  &__anchor {
    color: currentColor;
    white-space: nowrap;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 50ms;
    text-decoration: none;

    &:hover {
      color: primary(400);
    }

    &-text {
      font-size: 15px;
      line-height: 1;
    }
  }

  .icon {
    display: block;

    &:not(:first-child) {
      margin-left: 8px;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
