.asg-page-backdrop {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-repeat: no-repeat;
  background-size: cover;

  &.mobile {
    background-size: 100% 60%;
  }
}
