.accordion {
  font-size: 1rem;
  display: block;
  overflow: hidden;
  border-radius: $border-radius;

  &-header {
    $header: &;
    position: relative;
    width: 100%;
    padding: 1em 1.2em;
    cursor: pointer;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    user-select: none;
    border: 0;
    outline: 0;
    text-align: left;
    transition: $transition-duration;
    background-color: var(--N20);
    margin-top: 1rem;

    &:first-of-type {
      margin-top: 0;
    }

    &:hover,
    &[aria-expanded="true"] {
      background-color: var(--N30);
    }

    &:focus {
      -ieVar-background-color: var(--N40);
      background-color: var(--N40);
    }

    &[aria-expanded="true"] #{$header}-arrow {
      transform: rotateZ(-180deg);
    }

    &-icon-before {
      margin-right: 1em;
      display: block;
    }

    &-title {
      flex: 1;
      font-weight: 500;
      font-size: 0.875em;
      display: block;
    }

    &-icon-after {
      margin-left: 1em;
      display: block;
    }

    .icon {
      display: block;
    }
  }

  &-panel {
    width: 100%;
    max-height: 0px;
    padding: 0;
    overflow: hidden;
    -ieVar-background-color: var(--N20);
    background-color: var(--N20);
    transition: max-height 150ms linear;

    &-area {
      padding: .9em 1.2em;
      box-sizing: border-box;
    }
  }
}

