$font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$code-font-family: Menlo, Monaco, Lucida Console, Liberation Mono,
  DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;

// Sizes
$unit-size: 4px;
$font-size: 16px;
$transition-duration: 100ms;

// font
$text-size: 14px;
$small-text-size: 12px;
$font-weight-100: 100;
$font-weight-200: 200;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;
$font-weight-900: 900;
$font-weight: $font-weight-400;

// border radius
$border-radius-xl: $unit-size * 8;
$border-radius-lg: $unit-size * 6;
$border-radius-md: $unit-size * 4;
$border-radius-sm: $unit-size * 2;
$border-radius-xs: $unit-size * 1;
$border-radius-none: 0;
$border-radius: $border-radius-xs;

// paddings
$padding-xl: $unit-size * 8;
$padding-lg: $unit-size * 6;
$padding-md: $unit-size * 4;
$padding-sm: $unit-size * 2;
$padding-xs: $unit-size * 1;
$padding: $padding-md;
$padding-none: 0;

// margins
$margin-xl: $unit-size * 8;
$margin-lg: $unit-size * 6;
$margin-md: $unit-size * 4;
$margin-sm: $unit-size * 2;
$margin-xs: $unit-size * 1;
$margin: $margin-md;
$margin-none: 0;

// z-index
$z-index: auto;
$z-index-card: 100;
$z-index-dialog: 300;
$z-index-navigation: 200;
$z-index-layer: 400;
$z-index-blanket: 500;
$z-index-modal: 510;
$z-index-flag: 600;
$z-index-spotlight: 700;
$z-index-tooltip: 800;

// break points
$breakpoints-es: 360px;
$breakpoints-es-min: $breakpoints-es;
$breakpoints-es-max: $breakpoints-es-min - 1px;
$breakpoints-xs: 480px;
$breakpoints-xs-min: $breakpoints-xs;
$breakpoints-xs-max: $breakpoints-xs-min - 1px;
$breakpoints-sm: 768px;
$breakpoints-sm-min: $breakpoints-sm;
$breakpoints-sm-max: $breakpoints-sm-min - 1px;
$breakpoints-md: 1024px;
$breakpoints-md-min: $breakpoints-md;
$breakpoints-md-max: $breakpoints-md-min - 1px;
$breakpoints-lg: 1200px;
$breakpoints-lg-min: $breakpoints-lg;
$breakpoints-lg-max: $breakpoints-lg-min - 1px;
$breakpoints-xl: 1440px;
$breakpoints-xl-min: $breakpoints-lg;
$breakpoints-xl-max: $breakpoints-lg-min - 1px;

$media-es: '(max-width: #{$breakpoints-es})';
$media-lg: '(max-width: #{$breakpoints-lg})';
$media-md: '(max-width: #{$breakpoints-md})';
$media-sm: '(max-width: #{$breakpoints-sm})';
$media-xl: '(max-width: #{$breakpoints-xl})';
$media-xs: '(max-width: #{$breakpoints-xs})';
