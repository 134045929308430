.cookie-manager {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  font-size: 12px;
  text-align: center;
  box-sizing: border-box;
  z-index: 190;

  &.is-open {
    z-index: 300;
  }

  &-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    -ievar-background-color: var(--primary-color);
    background-color: rgba(light-neutral-rgb(0), 0.75);
    color: dark-neutral(0);

    p {
      padding-right: 15px;
      margin-bottom: 0px;
      max-width: calc(100% - 200px);
      font-size: 12px;
    }
  }

  &-buttons {
    display: flex;
    justify-content: center;
  }

  &-button {
    -ievar-background-color: var(--secondary-color);
    background-color: dark-neutral(0);
    cursor: pointer;
    padding: 5px 10px;
    color: light-neutral(0);
    font-weight: 600;
    font-size: 11px;
    margin-right: 7.5px;
    border-radius: 10px;

    &.stable {
      background-color: light-neutral(70);
      color: #000;
    }
  }

  &-tab-button {
    -ievar-background-color: var(--secondary-color);
    background-color: dark-neutral(0);
    cursor: pointer;
    padding: 12.5px 22.5px;
    font-weight: 600;
    font-size: 11px;
    margin-right: 7.5px;
    border-radius: 12.5px;
    color: #fff;
    transition: all, 0.4s;

    &.stable {
      background-color: #fff;
      color: #000;
    }

    &:hover {
      background-color: dark-neutral(20);
      color: light-neutral(0);
      transition: all, 0.4s;
    }
  }

  &-modal {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;

    &-wrapper {
      border-radius: 7.5px;
      background: #f0f0f0;
      position: relative;
      z-index: 9;
      max-width: 800px;
      display: flex;

      &.is-dark {
        background: #000;
      }
    }

    &-body {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      background: #000000b3;
      height: 100%;
    }

    &-content {
      flex: 0 0 calc(100% - 295px);
      max-width: calc(100% - 295px);
      -ms-flex: 0 0 calc(100% - 295px);
      color: #000;

      &.is-dark {
        color: #fff;
      }
    }

    &-foot {
      padding: 1rem;
      position: relative;
      left: 0px;
      display: flex;
      border-top: 1px solid #e7e7e7;
      background: #e8e8e8;
      border-bottom-right-radius: 7.5px;
      border-bottom-left-radius: 7.5px;

      &.is-dark {
        border-top: 1px solid #0c0c0c;
        background: #050505;
      }
    }

    &-panel {
      max-width: 255px;
      flex: 0 0 255px;
      -ms-flex: 0 0 255px;
      background: #fff;
      box-shadow: 1px 0px 5px -1px rgba(0, 0, 0, 0.15);
      color: #000;
      text-align: left;
      padding: 20px;
      border-top-left-radius: 7.5px;
      border-bottom-left-radius: 7.5px;
      border-top-right-radius: 7.5px;

      &.is-dark {
        background: #050505;
        color: #fff;
      }

      .title {
        font-weight: 600;
        font-size: 20px;
        -ievar-color: var(--secondary-color);
        color: var(--secondary-color);
        margin-bottom: 15px;

        &.is-dark {
          color: #fff;
        }
      }

      z-index: 5;
      position: relative;
    }

    &-close {
      position: absolute;
      right: -22.5px;
      top: -22.5px;
      width: 45px;
      height: 45px;
      border-radius: 100%;
      -ievar-background: var(--secondary-color);
      background: dark-neutral(0);
      line-height: 45px;
      text-align: center;
      cursor: pointer;
      z-index: 9;

      span {
        font-size: 14px;
        color: #fff;
      }
      &:hover {
        background-color: dark-neutral(10);
      }
    }
  }

  &-tab-title {
    background: #f0f0f0;
    cursor: pointer;
    padding: 12.5px 10px;
    font-weight: 600;
    font-size: 11.5px;
    border-radius: 3.5px;
    margin: 5px 0px;
    border: 1px solid #ececec;
    transition: all, 0.25s;
    -webkit-transition: all, 0.25s;

    &:hover {
      background: #fff;
    }

    &.is-active {
      -ievar-color: var(--secondary-color);
      color: var(--secondary-color);
      background: #fff;
    }

    &.is-dark {
      background: #0c0c0c;
      border: 1px solid #131313;

      &:hover {
        background: #131313;
      }

      &.is-active {
        color: #fff;
        background: var(--secondary-color);
      }
    }
  }

  &-tab-content {
    padding: 20px;
    text-align: left;
    min-height: 225px;
    color: #000;

    &.is-dark {
      color: #fff;
    }
  }

  &-tab-item {
    .item-value {
      margin: 15px 0px;

      .toggle-switch {
        position: relative;
        display: flex;
        align-items: center;
        width: 50px;
        height: 27px;

        input {
          opacity: 0;
          width: 0;
          height: 0;

          &:checked + .toggle-slider {
            background-color: #4bb880 !important;

            &.disabled {
              background: #000;

              &.is-dark {
                background: #3f3e3e;
              }
            }
          }

          &:checked + .toggle-slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
            left: 0px;
          }

          &:focus + .toggle-slider {
            box-shadow: 0 0 1px #2196f3;
          }
        }

        .toggle-slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 34px;

          &.is-dark {
            background: #7a7a7a;
          }

          &.disabled {
            opacity: 0.35;
          }

          &:before {
            position: absolute;
            content: '';
            height: 20px;
            width: 20px;
            left: 3px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
          }
        }
      }

      .toggle-text {
        position: relative;
        left: 60px;
        font-weight: 600;
        font-size: 11px;
        cursor: pointer;
        min-width: 200px;
      }
    }

    .item-title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 15px;
    }
  }

  &.is-open {
    z-index: 300;
  }

  &.is-dark {
    .cookie-manager-modal {
      &-wrapper {
        background: #000 !important;
      }

      &-content {
        color: #fff !important;
      }

      &-foot {
        border-top: 1px solid #0c0c0c !important;
        background: #050505 !important;
      }

      &-panel {
        background: #050505 !important;
        color: #fff !important;
        .title {
          color: #fff !important;
        }
      }
    }

    .cookie-manager-tab {
      &-title {
        background: #0c0c0c !important;
        border: 1px solid #131313 !important;

        &:hover {
          background: #131313 !important;
        }

        &.is-active {
          color: #fff !important;
          -ievar-background: var(--secondary-color) !important;
          background: var(--secondary-color) !important;
        }
      }
      &-content {
        color: #fff !important;
      }
      &-item {
        .item-value {
          .toggle-switch {
            input {
              &:checked + .toggle-slider {
                &.disabled {
                  background: #3f3e3e !important;
                }
              }
            }
            .toggle-slider {
              background: #7a7a7a;
            }
          }
        }
      }
    }
  }
}

@media #{$media-md} {
  .cookie-manager {
    &-bar {
      display: block;

      p {
        max-width: 100% !important;
        padding: 0px !important;
        margin-bottom: 5px !important;
      }
    }

    &-buttons {
      max-width: 100%;
    }
  }
}

@media #{$media-sm} {
  .cookie-manager {
    &-modal {
      &-wrapper {
        max-width: calc(100% - 50px);
        display: block;
      }

      &-content {
        max-width: 100%;
        flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        display: block;
      }

      &-panel {
        max-width: 100%;
        flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        display: block;
      }

      &-close {
        right: -20px;
        top: -20px;
        width: 40px;
        height: 40px;
        line-height: 40px;

        span {
          font-size: 13px;
        }
      }
    }

    &-tab-content {
      min-height: 100%;
      height: 35vh;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #e7e7e7;
      }

      &::-webkit-scrollbar-thumb {
        background: #bbbbbb;
      }
    }

    &-tab-item {
      .item-title {
        font-size: 13.5px !important;
        margin-bottom: 0px !important;
      }

      .item-content {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
}

@media #{$media-xs} {
  .cookie-manager {
    &-modal {
      &-wrapper {
        max-width: calc(100% - 50px);
        display: block;
      }

      &-content {
        max-width: 100%;
        flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        display: block;
      }

      &-panel {
        max-width: 100%;
        flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        display: block;
      }

      &-close {
        right: -17.5px;
        top: -17.5px;
        width: 35px;
        height: 35px;
        line-height: 35px;

        span {
          font-size: 12px;
        }
      }
    }

    &-tab-content {
      min-height: 100%;
      height: 34vh;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #e7e7e7;
      }

      &::-webkit-scrollbar-thumb {
        background: #bbbbbb;
      }
    }

    &-tab-item {
      .item-title {
        font-size: 13.5px !important;
        margin-bottom: 0px !important;
      }

      .item-content {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
}

.cookie-manager-indicator {
  .c-button {
    margin-top: 25px;
  }
}
