.scroll {
  $scroll-width: 4px;

  position: relative;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  box-sizing: border-box;

  &--position {
    &-y {
      overflow-x: hidden;
      overflow-y: auto;
      padding-right: $scroll-width;

      &::-webkit-scroll {
        width: $scroll-width;
      }
    }

    &-x {
      overflow-x: auto;
      overflow-y: hidden;
      padding-bottom: $scroll-width;

      &::-webkit-scroll {
        height: $scroll-width;
      }
    }

    &-xy {
      overflow: auto;
      padding-right: $scroll-width;
      padding-bottom: $scroll-width;

      &::-webkit-scroll {
        width: $scroll-width;
        height: $scroll-width;
      }
    }
  }

  &::-webkit-scroll-track {
    border-radius: 5px;
    background: neutral(40);
  }

  &::-webkit-scroll-thumb {
    border-radius: 5px;
    background: neutral(100);
  }
}
