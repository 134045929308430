.checkbox {
  $checkbox: &;
  max-width: 100%;
  font-size: 1rem;
  display: inline-flex;
  cursor: pointer;
  margin: 4px;

  &:hover #{$checkbox}-fake {
    -ievar-background-color: neutral(30);
    background-color: neutral(30);
  }

  &--state-checked .checkbox-fake {
    border-color: blue(400) !important;
    background-color: blue(400) !important;

    .icon-check {
      opacity: 1;
    }
  }

  &-input {
    position: relative;
  }

  &-real {
    left: 50%;
    opacity: 0;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    pointer-events: none;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
  }

  &-fake {
    width: 20px;
    height: 20px;
    -ievar-border: 2px solid neutral(30);
    border: 2px solid neutral(30);
    border-radius: 3px;
    display: block;
    padding: 3px;
    line-height: 0;
    box-sizing: border-box;
    transition: $transition-duration;

    .icon-check {
      color: #fff;
      font-size: 10px !important;
      transition: 75ms;
      opacity: 0;
    }
  }

  &-text {
    flex: 1;
    max-width: 100%;
    font-size: 0.875em;
    padding-right: 4px;
    padding-left: 8px;
    line-height: 20px;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;
    box-sizing: border-box;
  }
}
