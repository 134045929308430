.asg-container {
  background: #fff;
}

.team-item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  .column {
    display: flex;
    justify-content: center;
    .item {
      margin: 20px 0;
      padding: 30px 40px;
      box-sizing: border-box;
      background-color: #1d1d1d;
      border-radius: 30px;
      text-align: center;
      max-width: 315px;
      .text-wrapper {
        margin-top: 27px;
        .name-surname {
          font-size: 20px;
          font-weight: 500;
          overflow: hidden;
        }
        .title {
          display: block;
          font-size: 12px;
          color: #838383;
        }
      }
    }
  }
}

@media #{$media-sm} {
  .team-item-wrapper {
    .item {
      padding: 22.5px 38px !important;
      margin: 15px 0 !important;
      .text-wrapper {
        .name-surname {
          font-size: 23px !important;
        }
        .title {
          font-size: 13px !important;
        }
      }
    }
  }
}
