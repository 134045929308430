.post-item {
  margin: 0.5rem 0;
}

.post-anchor {
  color: currentColor;
  display: block;
  text-decoration: none;
}

.post-image {
  overflow: hidden;
  border-radius: 40px;
}

.post-content {
  padding-top: 1rem;
  box-sizing: border-box;
}

.post-title {
  font-size: 1.3em;
  font-weight: 900;
  margin: 0;
}

.post-category {
  font-size: 0.9375em;
  font-weight: 500;
  margin: 0 0 0.25em 0;
}

.post-description {
  font-size: 0.9375em;
  font-weight: 500;
  line-height: 1.45;
}
