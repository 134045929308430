@import '~normalize.css/normalize.css';

.h1,
h1 {
  font-size: 2.1875em;
  margin: 0.5em 0;
  font-weight: bold;
}

.h2,
h2 {
  font-size: 1.8125em;
  margin: 0.7em 0;
  font-weight: bold;
}

.h3,
h3 {
  font-size: 1.5em;
  margin: 0.9em 0;
  font-weight: bold;
}

.h4,
h4 {
  font-size: 1.25em;
  margin: 1.1em 0;
  font-weight: bold;
}

.h5,
h5 {
  font-size: 1em;
  margin: 1.3em 0;
  font-weight: bold;
}

.h6,
h6 {
  font-size: 0.9375em;
  margin: 1.5em 0;
  font-weight: bold;
}

p {
  font-size: 0.9375em;
  line-height: 1.55;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

p,
ul,
ol,
dl,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
form,
table {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

ul,
ol {
  font-size: 0.9375em;
}

dd,
dd + dt,
li + li {
  margin-top: 4px;
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin-top: 4px !important;
}

.blockquote,
blockquote {
  border: none;
  padding-left: 40px;

  &::before {
    float: left;
    margin-left: -1em;
    text-align: right;
    width: 1em;
    content: '\201C';
  }

  &::after {
    content: '\201D';
  }

  & > :last-child {
    margin: 0;
    display: inline-block;
  }
}

table {
  border-collapse: separate;
  width: 100%;
  border-spacing: 0;
  border: 1px solid neutral(40);
  border-radius: $border-radius-xs;
  -webkit-overflow-scrolling: touch;
}

tr:nth-child(2n) td {
  background-color: neutral(20);
}

td {
  font-size: 0.9375em;
  text-align: left;
  border: none;
  padding: $padding-md;
  box-sizing: border-box;
}

th {
  font-size: 0.9375em;
  text-align: left;
  border: none;
  font-weight: 600;
  padding: $padding-md;
  box-sizing: border-box;
  background-color: neutral(30);
}

.figure,
figure {
  margin: 0;
}

.caption,
caption {
  font-size: 1.42em;
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: -0.008em;
  margin-top: 1em;
  margin-bottom: 8px;
  text-align: left;
}

.code,
code {
  font-family: $code-font-family;
  border-radius: 2px;
  font-size: inherit;
  padding: 2px;
  margin: 0px 1px;
  background-color: neutral(40);
}

.hr,
hr {
  margin: 1.5rem 0;
  height: 1px;
  border: 0;
  background-color: neutral(30);
}

.mark,
mark {
  padding: 0 4px;
  border-radius: 2px;
  background-color: yellow(75);
}

[role='button'] {
  cursor: pointer;
}
