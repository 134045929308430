.bussines-card {
  display: flex;
  flex-direction: column;
  padding: 15px 30px;
  width: 480px;
  border: 1px solid light-neutral(0);
  border-radius: 17px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: light-neutral(0);
  color: dark-neutral(0);
  box-sizing: border-box;
  .row {
    border-bottom: 1px dotted dark-neutral(100);
    margin: 0 -15px;
    .col {
      padding: 15px;
    }
    &:last-child {
      border: none;
    }
  }
  .title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 600;
    span {
      display: block;
      font-size: 14px;
      color: dark-neutral(30);
      font-weight: 300;
    }
  }
  .contact {
    font-size: 14px;
    display: flex;
    &-item {
      margin: 0 10px;
      white-space: nowrap;
      &:first-child {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  .address {
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 1.2;
  }
  .web {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text);
    justify-content: flex-end;
    .c-icon {
      margin-right: 10px;
    }
  }
  .buttons {
    display: flex;
    margin-top: 15px;
    .button {
      display: inline-block;
      align-items: center;
      padding: 8px 10px;
      font-size: 14px;
      font-weight: 600;
      color: dark-neutral(0);
      border-radius: 5px;
      background-color: dark-neutral(100);
      margin: 0 10px;
      width: max-content;
      .c-icon {
        font-size: 15px;
        margin-right: 10px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background-color: dark-neutral(0);
        color: light-neutral(0);
      }
    }
  }
}

@media #{$media-sm} {
  .bussines-card {
    width: 90%;
    .contact {
      flex-flow: column;
      &-item {
        margin: 2.5px 0;
      }
    }
    .buttons {
      flex-flow: column;
      .button {
        margin: 2.5px 0;
      }
    }
  }
}
