.image-list {
  &-item {
    &--rotated {
      .image-list-item-content {
        position: absolute;
        bottom: 0;
        width: calc(100% - 32px) !important;
        z-index: 1;
        pointer-events: none;
        padding: 32px 16px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(255, 255, 255, 0) 100%
          );
          z-index: 0;
          pointer-events: none;
        }

        h4 {
          transform: inherit;
          position: relative;
          left: 0;
          bottom: 0;
          margin: 0px;
          z-index: 2;
        }

        p {
          margin: 0px;
          position: relative;
          z-index: 2;
        }
      }
    }

    &-with-title {
      position: relative;
      overflow: hidden;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        z-index: 1;
        pointer-events: none;
        border-radius: 50px;
      }

      h4 {
        margin: 0px;
        position: absolute;
        z-index: 1;
        transform-origin: bottom left;
        left: 20px;
        bottom: 16px;
        font-size: 1.62vw;
        line-height: 100%;
        transform: rotate(-90deg) translateY(100%);
        pointer-events: none;
        color: #fff;
        text-align: left;
      }
    }
  }

  .image-lozenges {
    position: absolute;
    left: 16px;
    top: 16px;
    display: flex;
    flex-direction: column;
  }

  .image-lozenge {
    z-index: 1;
    display: flex;
    align-items: center;
    background: #fff;
    padding: 8px 12px;
    border-radius: 20px;
    color: dark-neutral(900);
    pointer-events: none;
    margin-bottom: 6px;

    img {
      height: 18px;

      &:nth-child(2) {
        padding-left: 6px;
        border-left: 1px solid #b9b9b9;
        margin-left: 6px;
      }
    }

    span {
      margin-left: 8px;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
    }
  }

  &-down {
    h4 {
      display: none;
    }

    h6 {
      margin-top: 1rem;
      font-weight: 600;
      font-family: "Red Hat Display", sans-serif;
    }
  }

  img {
    border-radius: 50px;
  }
}

@media #{$media-xl} {
  .image-list {
    h4 {
      font-size: 1.75vw;
    }
  }
}

@media #{$media-sm} {
  .image-list {
    img {
      border-radius: 25px;
    }

    &-item {
      &-with-title {
        &:before {
          border-radius: 25px;
        }
      }
    }
  }
}

@media #{$media-xs} {
  .image-list {
    h4 {
      font-size: 1.28em;
      bottom: 12px;
      left: 12px;
    }
  }
}
