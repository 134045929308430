.sustainability {
  $root: &;

  &-slideshow {
    $slideshow: &;

    &-tracker {
      margin: 4rem 0px 4rem 4rem;
      white-space: nowrap;

      #{$slideshow}-item {
        width: 18vw;
        display: inline-block;
        height: 100%;
        position: relative;
        margin: 0 10px;
        transform: translateX(calc(var(--index) * -18vw));
        transition: all 350ms ease-in-out,
          scale cubic-bezier(0.42, 0, 0.58, 1.71);
        white-space: normal;

        &[data-current="true"] {
          z-index: 10;
          margin: 0 30px 0 30px;
          transform: translateX(calc(var(--index) * -18vw)) scale(1.05);
          width: 42vw;

          #{$root}-card {
            &-caption {
              opacity: 0;
              pointer-events: none;
              bottom: 8px;
              transition: all, 0s;

              &--dynamic {
                opacity: 1;
                pointer-events: auto;
                transition: all, 0.35s;
                transition-delay: 0.35s;
                bottom: 0;

                p {
                  -webkit-line-clamp: 10;
                  line-clamp: 10;
                }
              }
            }
          }
        }
      }
    }

    &:hover {
      #{$slideshow}-button {
        opacity: 1;
      }
    }

    &-button {
      background-color: transparent;
      position: absolute;
      width: 90px;
      height: 75vh;
      top: 50%;
      margin-top: -34vh;
      z-index: 100;
      transform: none;
      display: block;
      border: 0;
      outline: 0;
      cursor: pointer;
      box-sizing: border-box;
      opacity: 0;
      transition: all, 0.25s;

      span {
        color: dark-neutral(0);
        font-size: 30px;
        z-index: 9;
        position: relative;
      }

      &--prev {
        left: 0;
      }

      &--next {
        right: 0;
      }
    }
  }

  &-card {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 50%;
      bottom: 0;
      left: 0;
      z-index: 0;
      opacity: 0.75;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgb(0, 0, 0) 100%
      );
      border-radius: 8px;
    }

    &-caption {
      $caption: &;
      position: absolute;
      bottom: 0;
      z-index: 1;
      padding: 2.2rem 2rem;
      height: 50%;
      width: calc(100% - 4rem);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      transition: all, 0.4s;
      transition-delay: 1s;

      h4 {
        color: #fff;
        font-size: 1.8rem;
        margin: 1.2rem 0px;
        z-index: 1;
        font-weight: 800;
      }

      p {
        margin-top: 0px;
        color: #fff;
        z-index: 1;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      a {
        color: #fff;
        z-index: 1;
        position: relative;
      }

      &-social {
        opacity: 0;
        transition: all, 0.35s;
        pointer-events: none;
      }

      &--dynamic {
        opacity: 0;
        pointer-events: none;
        transition: all, 0.35s;
        bottom: 8px;

        #{$caption}-social {
          opacity: 1;
          pointer-events: all;
        }

        h4 {
          font-size: 2.4rem;
        }
      }
    }

    &-image {
      border-radius: 50px;
      overflow: hidden;

      img {
        height: 75vh;
        object-fit: cover;
      }
    }
  }
}

@media #{$media-xl} {
  .sustainability {
    $root: &;

    &-slideshow {
      $slideshow: &;

      &-tracker {
        #{$slideshow}-item {
          width: 24vw;
          transform: translateX(calc(var(--index) * -24vw));

          &[data-current="true"] {
            transform: translateX(calc(var(--index) * -24vw)) scale(1.05);
            width: 42vw;
          }
        }
      }
    }
  }
}

@media #{$media-lg} {
  .sustainability {
    $root: &;

    &-slideshow {
      $slideshow: &;

      &-tracker {
        #{$slideshow}-item {
          width: 24vw;
          transform: translateX(calc(var(--index) * -24vw));

          &[data-current="true"] {
            transform: translateX(calc(var(--index) * -24vw)) scale(1.05);
            width: 42vw;
          }
        }
      }

      &-button {
        opacity: 1;
        height: 50vh;
      }
    }

    &-card {
      &-image {
        height: 70vh;
      }
    }
  }
}

@media #{$media-sm} {
  .sustainability {
    &-slideshow {
      .slick-arrow {
        display: none !important;
      }

      .slick-list {
        margin: 0px 28px !important;
      }
    }

    &-card {
      width: 100%;

      &:after {
        height: 65%;
        opacity: 0.6;
      }

      &-caption {
        width: calc(100% - 2.8rem);
        padding: 1.4rem;

        &-social {
          opacity: 1;
        }

        &--dynamic {
          display: none;
        }

        h4 {
          margin: 0.8rem 0;
          font-size: 1.6rem;
        }

        p {
          width: 100%;
        }

        a {
          span {
            font-size: 18px;
          }
        }
      }

      &-image {
        height: 70vh;
        border-radius: 25px;
      }
    }
  }
}
