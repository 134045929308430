.hero {
  overflow: hidden;
  margin-bottom: 4rem;
  height: 100vh;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: dark-neutral(900);
    pointer-events: none;
    z-index: 22;
    transition: all, 1s;
    opacity: 1;
    pointer-events: none;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.4);
    pointer-events: none;
    z-index: 1;
  }

  &--loaded {
    &:before {
      opacity: 0;
    }
  }

  &-content {
    position: absolute;
    z-index: 11;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;

    &-caption {
      animation: fadeIn 800ms ease-in-out;
    }

    h1 {
      font-size: 10.2vw;
      line-height: 8.4vw;
      font-weight: 900;
      margin-top: 10px;
    }

    h6 {
      font-size: 24px;
    }

    p {
      max-width: 40%;
      text-align: center;
      margin: 0px auto;
      font-size: 24px;
      line-height: 120%;
    }
  }

  &-image {
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      opacity: 1;
    }
  }

  &-video {
    video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }

  &-footer {
    position: absolute;
    z-index: 99;
    bottom: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;

    .container-extended {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      text-align: right;
    }

    .breadcrumb-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 8px;
    }
  }
}

@keyframes fadeIn {
  0% {
    margin-top: 20%;
    opacity: 0;
  }
  100% {
    margin-bottom: 0;
    opacity: 1;
  }
}

@media #{$media-md} and (orientation: landscape) {
  .hero {
    &-content {
      p {
        display: none;
      }
    }

    &-footer {
      bottom: 12px;

      &-social {
        width: 100%;
      }

      .breadcrumb,
      .c-iconlist {
        justify-content: flex-end;
      }
    }
  }
}

@media #{$media-sm} {
  .hero {
    max-height: -webkit-fill-available;

    &-content {
      p {
        max-width: 70%;
      }
    }

    &-footer {
      bottom: 12px;

      &-social {
        width: 100%;
      }

      .breadcrumb,
      .c-iconlist {
        justify-content: center;
      }
    }
  }
}

@media #{$media-xs} {
  .hero {
    max-height: -webkit-fill-available;

    &-content {
      h1 {
        font-size: 42px !important;
        line-height: 40px !important;
      }

      p {
        max-width: 100%;
        font-size: 19px;
      }
    }

    &-footer {
      bottom: 12px;

      &-social {
        width: 100%;
      }

      .breadcrumb-container {
        margin-top: 4px;
      }

      .c-iconlist {
        justify-content: flex-start;
      }

      .breadcrumb {
        justify-content: flex-start;
        overflow-x: auto;
      }
    }
  }
}
