.goturkiyebreadcrumb {
  $breadcrumb: &;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 10px;

  &-container {
    width: 100%;
    position: sticky;
    top: 100px;
    z-index: 99;
  }

  &-item {
    $item: &;
    position: relative;

    &:not(:first-child) {
      padding-left: 6px;
    }

    &:not(:last-child) {
      padding-right: 24px;

      #{$item}-separator {
        opacity: 1;
      }
    }

    &:last-child {
      #{$item}-anchor {
        font-weight: 600;
        pointer-events: none;
        opacity: 1;

        &:after {
          display: none;
        }
      }
    }

    &-anchor {
      color: currentColor;
      font-size: 0.9375rem;
      white-space: nowrap;
      display: flex;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      opacity: 0.6;

      &:after {
        content: "";
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        right: 6px;
        background: dark-neutral(0);
        opacity: 0.8;
      }

      &:focus {
        outline: 2px solid currentColor;
        outline-offset: 4px;
      }
    }

    button {
      font-size: 16px;

      .c-button__icon-after {
        span {
          font-size: 14px;
          margin-left: 8px;
        }
      }

      &:after {
        content: "";
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        right: -16px;
        background: dark-neutral(0);
        opacity: 0.8;
      }
    }

    .button-menu-wrapper {
      background-color: var(--status-stable-color);
      border-radius: 12px;

      &-item {
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);

        &:last-child {
          border-bottom: none;
        }

        button {
          font-size: 14px;
        }
      }
    }

    &-text {
      flex: 1;
      line-height: 1.2;
    }

    &-icon-before,
    &-icon-after {
      display: block;

      i {
        display: block;
        font-size: 1em;
      }
    }

    &-icon-before:not(:last-child) {
      padding-right: 8px;
    }

    &-icon-after:not(:first-child) {
      padding-left: 8px;
    }

    &-separator {
      position: absolute;
      top: 50%;
      right: 0;
      opacity: 0;
      font-size: 20px !important;
      transform: translateY(-50%);
    }
  }
}

@media #{$media-sm}, #{$media-md} {
  .goturkiyebreadcrumb {
    -webkit-flex-wrap: unset;
    flex-wrap: unset;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    &-container {
      top: 74px;
    }

    &-item {
      &:not(:last-child) {
        padding-right: 16px;
      }

      &:not(:first-child) {
        padding-left: 0px;
      }

      &-anchor {
        font-size: 0.875rem;
      }

      button {
        margin-left: 4px;
        margin-right: 8px;
        font-size: 0.875rem;
      }
    }
  }
}
