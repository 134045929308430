.image-slideshow {
  &-item {
    $item: &;

    &--last {
      #{$item}-with-title {
        backdrop-filter: blur(5px);

        &:before {
          background-color: primary(400);
          opacity: 0.8;
        }

        img {
          filter: blur(4px);
        }
      }
    }

    &--rotated {
      .image-slideshow-item-content {
        position: absolute;
        bottom: 0;
        width: calc(100% - 32px);
        z-index: 1;
        pointer-events: none;
        padding: 32px 16px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(255, 255, 255, 0) 100%
          );
          z-index: 0;
          pointer-events: none;
          border-radius: 50px;
        }

        h4 {
          transform: inherit;
          position: relative;
          left: 0;
          bottom: 0;
          margin: 0px;
          z-index: 2;
        }

        p {
          margin: 0px;
          position: relative;
          z-index: 2;
        }
      }
    }

    &-with-title {
      position: relative;
      overflow: hidden;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        z-index: 1;
        pointer-events: none;
        border-radius: 50px;
      }

      h4 {
        margin: 0px;
        position: absolute;
        z-index: 1;
        transform-origin: bottom left;
        font-size: 2vw;
        left: 20px;
        bottom: 24px;
        line-height: 100%;
        transform: rotate(-90deg) translateY(100%);
        pointer-events: none;
        color: #fff;
        text-align: left;
      }
    }
  }

  .image-lozenges {
    position: absolute;
    left: 16px;
    top: 16px;
    display: flex;
    flex-direction: column;
  }

  .image-lozenge {
    z-index: 1;
    display: flex;
    align-items: center;
    background: #fff;
    padding: 8px 12px;
    border-radius: 20px;
    color: dark-neutral(900);
    pointer-events: none;
    margin-bottom: 6px;

    img {
      height: 18px;

      &:nth-child(2) {
        padding-left: 6px;
        border-left: 1px solid #b9b9b9;
        margin-left: 6px;
      }
    }

    span {
      margin-left: 8px;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
    }
  }

  &-list {
    .row {
      justify-content: center;
    }
  }

  &-down {
    h4 {
      display: none;
    }

    h6 {
      margin-top: 1rem;
      font-weight: 600;
    }
  }

  &-shadow {
    .image-list-item-with-title,
    .image-slideshow-item-with-title {
      &:before {
        background: rgb(255, 255, 255);
        background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(0, 0, 0, 0.47102591036414565) 100%
        );
      }
    }
  }

  img {
    border-radius: 50px;
  }
}

@media #{$media-xl} {
  .image-slideshow {
    h4 {
      font-size: 1.8em;
    }
  }
}

@media #{$media-sm} {
  .image-slideshow {
    img {
      border-radius: 25px;
    }

    &-item {
      &-with-title {
        &:before {
          border-radius: 25px;
        }
      }
    }
  }
}

@media #{$media-xs} {
  .image-slideshow {
    h4 {
      font-size: 1.68em;
      bottom: 12px;
      left: 12px;
    }
  }
}

@media #{$media-es} {
  .image-slideshow {
    h4 {
      font-size: 1.4em;
      bottom: 8px;
      left: 8px;
    }
  }
}
