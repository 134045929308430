@mixin contained($appearance, $color, $background-color) {
  .lozenge--contained-#{$appearance} {
    color: $color;
    background-color: $background-color;
  }
}

@mixin outlined($appearance, $color, $border-color) {
  .lozenge--outlined-#{$appearance} {
    color: $color;
    border-color: $border-color;
  }
}

@mixin standard($appearance, $color, $background-color) {
  .lozenge--standard-#{$appearance} {
    color: $color;
    background-color: $background-color;
  }
}

@mixin shape($type, $border-radius) {
  .lozenge--shape-#{$type} {
    border-radius: $border-radius;
  }
}

@mixin size($type, $font-size, $height, $padding) {
  .lozenge--size-#{$type} {
    font-size: $font-size;
    min-width: $height;
    line-height: $height - 2px;
    height: $height;
    padding: $padding;
  }
}

.lozenge {
  border: 1px solid transparent;
  box-sizing: border-box;
  display: inline-block;
  font-weight: 700;
  margin: 2px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

@include contained(default, neutral(30), neutral(700));
@include contained(error, light-neutral(0), red(400));
@include contained(help, light-neutral(0), purple(400));
@include contained(info, light-neutral(0), blue(400));
@include contained(success, light-neutral(0), green(400));
@include contained(warning, light-neutral(0), yellow(400));

@include outlined(default, neutral(600), neutral(40));
@include outlined(error, red(400), red(300));
@include outlined(help, purple(400), purple(300));
@include outlined(info, blue(400), blue(300));
@include outlined(success, green(400), green(300));
@include outlined(warning, yellow(400), yellow(300));

@include standard(default, neutral(700), neutral(30));
@include standard(error, red(500), red(50));
@include standard(help, purple(500), purple(50));
@include standard(info, blue(500), blue(50));
@include standard(success, green(500), green(50));
@include standard(warning, yellow(500), yellow(50));

@include shape(pill, 8px);
@include shape(square, 2px);
@include shape(none, 0);

@include size(xlarge, 14px, 28px, 0 10px);
@include size(large, 13px, 24px, 0 6px);
@include size(medium, 12px, 18px, 0 4px);
@include size(small, 11px, 16px, 0 2px);
@include size(xsmall, 10px, 14px, 0 2px);
