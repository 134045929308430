.slideshow {
  position: relative;
  padding-top: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  overflow: hidden;

  &:hover .slick-dots,
  &:hover .slick-arrow span {
    opacity: 1;
  }

  &.video-slideshow .slick-list {
    overflow: visible;
    margin: 0;
  }

  .slick-list {
    overflow: visible;
    margin: 0 100px;
  }

  .slick-slide {
    font-size: 0;
    padding: 0 8px;
    box-sizing: border-box;
  }

  .slick-slide-target {
    font-size: 1rem;
    box-sizing: border-box;
  }

  .slick-arrow {
    position: absolute;
    width: 92px;
    height: 100%;
    top: 0;
    z-index: 100;
    transform: none;
    display: block;
    border: 0;
    outline: 0;
    cursor: pointer;
    box-sizing: border-box;

    span {
      color: dark-neutral(0);
      font-size: 30px;
      opacity: 0;
      z-index: 9;
      position: relative;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      top: 0;
      z-index: 1;
      opacity: 0.75;
      z-index: 0;
    }

    -ievar-background: rgba(var(--primary-color-rgb), 0.5);
    background: rgba(var(--primary-color-rgb), 0.5);

    &.slick-prev {
      left: 0px;
    }

    &.slick-next {
      right: 0px;
    }
  }

  .slick-dots {
    position: absolute;
    width: auto;
    right: 1.5rem;
    bottom: 100%;
    font-size: 0;
    opacity: 0;
    margin: 0;
    padding: 0;

    li {
      width: auto;
      height: auto;
      margin: 0 1px;
      color: #4d4d4d;
      display: inline-block;

      &:last-child {
        display: none;
      }

      &.slick-active button {
        color: #aaa;
      }

      button {
        position: relative;
        height: 1rem;
        width: 12px;
        padding: 0;
        color: #4d4d4d;
        box-sizing: border-box;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        text-indent: -999px;
        overflow: hidden;
        outline: 0;
        font-size: 0;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          content: "";
          height: 2px;
          display: block;
          background-color: currentColor;
        }
      }
    }
  }
}

@media #{$media-xl} {
  .slideshow {
    .slick-list {
      margin: 0 90px;
    }

    .slick-arrow {
      width: 82px;
    }
  }
}

@media #{$media-md} {
  .slideshow {
    .slick-list {
      margin: 0 80px;
    }

    .slick-arrow {
      width: 72px;
    }
  }
}

@media #{$media-sm} {
  .slideshow {
    .slick-list {
      margin: 0 70px;
    }

    .slick-arrow {
      width: 62px;

      span {
        font-size: 20px;
      }
    }

    .slick-dots {
      display: none !important;
    }

    .slick-arrow span {
      opacity: 1 !important;
    }
  }
}

@media #{$media-xs} {
  .slideshow {
    .slick-list {
      margin: 0 60px;
    }

    .slick-arrow {
      width: 52px;
    }
  }
}
