.tabs {
  display: flex;
  max-width: 100%;
  min-height: 0%;

  &--orientation {
    &-horizontal {
      flex-direction: column;
    }

    &-vertical {
      flex-direction: row;
    }
  }
}

.tab {
  $tab: &;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: $padding-xs;
  padding: $padding-sm;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 0 solid transparent;
  border-bottom-width: 3px;

  &:focus {
    outline: 0;
  }

  &:hover {
    border-color: neutral(50);
  }

  &[aria-selected='true'] {
    border-color: primary(400);
  }

  &-list {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: $margin-sm;

    &:before {
      position: absolute;
      content: '';
      width: inherit;
      right: 0;
      bottom: 0;
      left: 0;
      height: 3px;
      background-color: neutral(30);
    }

    &[aria-orientation='vertical'] {
      flex-direction: column;

      &:before {
        top: 0;
        left: auto;
        width: 3px;
        height: inherit;
      }

      #{$tab} {
        border-bottom-width: 0;
        border-right-width: 3px;
      }
    }
  }

  &-panel {
    width: 100%;

    &:focus {
      outline-offset: -3px;
    }

    &-area {
      padding: $padding-xs $padding-sm;
      box-sizing: border-box;
    }
  }
}
