.video-player {
  position: relative;
  width: 100%;

  &.fullscreen-video {
    video {
      height: calc(100vh - 82px);
    }
  }

  &--material {
    .video-play-button {
      opacity: 0.5;
      background: transparent;

      &:hover {
        opacity: 0.7;
      }

      img {
        width: 3.25rem;
        height: 3.25rem;
        filter: brightness(1) invert(1);
      }
    }

    .video-content {
      color: #fff;

      h4 {
        margin-top: 1rem;
        margin-bottom: 0.2rem;
        color: #fff;
      }

      h5 {
        font-weight: bold;
        font-size: 1.3rem;
        color: #fff;
      }

      h6 {
        font-weight: 300;
        margin-top: 1rem;
        color: #fff;
      }

      p {
        font-weight: 300;
        color: #fff;
      }
    }

    video {
      border-radius: 50px;
      overflow: hidden;
    }

    .video-backdrop {
      border-radius: 50px;
    }
  }
}

.video-wrapper {
  position: relative;
  width: 100%;
}

.video-target {
  position: relative;
  width: 100%;
  object-fit: cover;
  z-index: 1;
  outline: none;
  display: block;
}

.video-backdrop {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000 100%);
  opacity: 0.8;
  z-index: 5;
}

.video-content-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 45%;
  z-index: 20;
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
  box-sizing: border-box;
  transition: #{$transition-duration};
}

.video-content {
  position: relative;
  flex: 1;
  z-index: 10;
  color: #fff;

  h4 {
    margin: 0 0 0.85em;
  }

  h5 {
    margin: 0 0 0.3em;
  }

  h6,
  p {
    margin: 0;
  }
}

.video-controls {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  z-index: 30;
}

.video-controls-button {
  opacity: 0.6;
  border: 0;
  color: #000;
  cursor: pointer;
  outline: none;
  padding: 0;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.4);
  border-radius: 100%;
  background-color: #fff;
  display: inline-block;
  vertical-align: top;

  span {
    display: block;
  }

  &:hover {
    opacity: 1;
  }
}

.video-controls-fullscreen-button {
  opacity: 0.6;
  border: 0;
  color: #fff;
  cursor: pointer;
  outline: none;
  padding: 6px;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.4);
  border-radius: 100%;
  background-color: #000;
  display: inline-block;
  vertical-align: top;
  border: 1px solid #fff;
  margin-left: 5px;

  span {
    display: block;
  }

  &:hover {
    opacity: 1;
  }
}

.video-play-button {
  position: absolute;
  left: 50%;
  top: 40%;
  z-index: 30;
  opacity: 0.6;
  border: 0;
  color: #000;
  cursor: pointer;
  outline: none;
  padding: 0;
  transform: translate(-50%);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.4);
  border-radius: 100%;
  background-color: #fff;

  &:hover {
    opacity: 1;
  }
}

.video-slideshow {
  position: relative;
  margin: 2rem 0;

  .slick-slide-target {
    padding: 30px 50px;
    transition: 250ms;
  }

  .slick-center .slick-slide-target {
    padding: 0;
  }

  .video-player--material {
    .video-play-button {
      img {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}

.video-list {
  .video-player--material {
    .viode-play-button {
      img {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}

@media #{$media-md} {
  .video {
    &-backdrop {
      height: 65%;
      opacity: 0.6;
    }
  }

  .video-slideshow {
    .video-player {
      &--material {
        .video-play-button {
          img {
            width: 2.5rem;
            height: 2.5rem;
          }
        }
      }
    }
  }

  .video-player {
    .video-content {
      color: dark-neutral(0);

      h4,
      h5,
      h6,
      p {
        color: dark-neutral(0);
      }

      &-wrapper {
        padding: 1.4rem;
        align-items: flex-end;
      }
    }

    &--material {
      .video-content {
        h5,
        h6 {
          display: none;
        }

        h4 {
          font-size: 1.45rem;
          font-weight: 800;
        }
      }

      video {
        height: 70vh;
        border-radius: 25px;
      }

      .video-backdrop {
        border-radius: 25px;
      }

      .video-play-button img {
        width: 3.5rem !important;
        height: 3.5rem !important;
      }
    }
  }

  .video-slideshow {
    .slick-slide-target {
      padding: 0px !important;
    }

    .slick-list {
      padding: 0px !important;
      margin: 0px 28px !important;
    }

    .slick-arrow {
      display: none !important;
    }
  }
}
