.animated-hero {
  $root: &;
  position: relative;
  height: 100vh;
  contain: paint;
  margin-bottom: 2.8rem;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: dark-neutral(900);
    z-index: 999;
    opacity: 1;
    pointer-events: none;
  }

  &--loaded {
    &:after {
      opacity: 0;
      transition: all, 0.4s;
      animation: fadeOut 0.4s ease-in-out;
    }

    #{$root}-headings {
      animation: slideIn 1.2s, fadeIn 1.6s ease-in-out;
    }
  }

  &-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    &--loaded {
      opacity: 1;
      transition: all, 1s;
      transition-delay: 1s;
      transform-origin: center;
      animation: fadeIn 1400ms, zoomOut 1400ms ease-in-out;
    }

    img {
      position: relative;
      height: 100vh;
      object-fit: cover;
      width: 100%;
      display: block;
    }
  }

  &-asset {
    $asset: &;
    position: absolute;
    display: none;

    img {
      height: 100%;
      object-fit: cover;
      position: relative;
      width: 100%;
      display: block;
    }

    &--loaded {
      display: block;

      &#{$asset} {
        &--bottom {
          animation: fadeIn, slideBottom ease-in-out;
        }

        &--top {
          animation: fadeIn, slideTop ease-in-out;
        }

        &--left {
          animation: fadeIn, slideLeft ease-in-out;
        }

        &--left {
          animation: fadeIn, slideRight ease-in-out;
        }

        &--top-left {
          animation: fadeIn, slideTopLeft ease-in-out;
        }

        &--top-right {
          animation: fadeIn, slideTopRight ease-in-out;
        }

        &--bottom-left {
          animation: fadeIn, slideBottomLeft ease-in-out;
        }

        &--bottom-right {
          animation: fadeIn, slideBottomRight ease-in-out;
        }

        &--zoom-out {
          animation: fadeIn, zoomOut ease-in-out;
        }

        &--zoom-in {
          animation: fadeIn, zoomIn ease-in-out;
        }
      }
    }
  }

  &-headings {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    top: 0;
    margin-top: 0px;
    z-index: 1;
    font-size: 11.2vw;
    line-height: 80%;
    font-weight: 900;

    span {
      position: relative;
      display: block;
    }

    &-container {
      height: 200vh;
    }
  }

  &-footer {
    position: absolute;
    z-index: 99;
    bottom: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;

    .container-extended {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      text-align: right;
    }

    .breadcrumb-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 8px;
    }
  }
}

/* Animations */
@keyframes slideIn {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideBottom {
  0% {
    margin-bottom: -30%;
  }
  100% {
    margin-bottom: 0;
  }
}

@keyframes slideTop {
  0% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideTopLeft {
  0% {
    margin-top: -10%;
    margin-left: -10%;
  }
  100% {
    margin-top: 0;
    margin-left: 0;
  }
}

@keyframes slideTopRight {
  0% {
    margin-top: -10%;
    margin-right: -10%;
  }
  100% {
    margin-top: 0;
    margin-right: 0;
  }
}

@keyframes slideBottomLeft {
  0% {
    margin-bottom: -10%;
    margin-left: -10%;
  }
  100% {
    margin-bottom: 0;
    margin-left: 0;
  }
}

@keyframes slideBottomRight {
  0% {
    margin-bottom: -10%;
    margin-right: -10%;
  }
  100% {
    margin-bottom: 0;
    margin-right: 0;
  }
}

@keyframes slideLeft {
  0% {
    margin-left: -30%;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes slideRight {
  0% {
    margin-right: -30%;
  }
  100% {
    margin-right: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes zoomOut {
  0% {
    opacity: 0;
    transform: scale(1.4);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media #{$media-md} and (orientation: landscape) {
  .animated-hero {
    &-footer {
      bottom: 12px;

      &-social {
        width: 100%;
      }

      .breadcrumb-container {
        margin-top: 4px;
      }

      .breadcrumb,
      .c-iconlist {
        justify-content: flex-end;
      }
    }

    &-headings {
      font-size: 75px !important;
    }
  }
}

@media #{$media-sm} {
  .animated-hero {
    &-footer {
      bottom: 12px;

      .breadcrumb,
      .c-iconlist {
        justify-content: flex-start;
        overflow-x: auto;
      }

      &-social {
        width: 100%;
      }
    }

    &-headings {
      font-size: 90px !important;
    }
  }
}

@media #{$media-sm} and (orientation: landscape) {
  .animated-hero {
    &-footer {
      bottom: 12px;

      &-social {
        width: 100%;
      }

      .breadcrumb-container {
        margin-top: 4px;
      }

      .breadcrumb,
      .c-iconlist {
        justify-content: center;
      }
    }

    &-headings {
      font-size: 75px !important;
    }
  }
}

@media #{$media-xs} {
  .animated-hero {
    &-footer {
      bottom: 12px;

      &-social {
        width: 100%;
      }

      .breadcrumb-container {
        margin-top: 4px;
      }

      .breadcrumb,
      .c-iconlist {
        justify-content: flex-start;
      }
    }

    &-headings {
      font-size: 60px !important;
    }
  }
}
