.gallery-slideshow {
  &.slideshow {
    .slick-arrow {
      background-color: transparent;
      width: 40px;

      span {
        font-size: 22px;
      }
    }

    .slick-list {
      margin: 0px;
      overflow: hidden;
    }

    .slick-slide {
      padding: 0px 4px;
    }
  }

  &-pager {
    $pager: &;
    padding: 0;
    list-style-type: none;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 2rem;
    z-index: 11;

    .slick-track {
      display: flex;
    }

    li {
      margin: 0;
      box-sizing: border-box;
      border-radius: 15px;
      overflow: hidden;

      img {
        border-radius: 15px;
        height: 80px;
        object-fit: cover;
      }
    }

    &-item {
      &--active {
        opacity: 0.5;
      }
    }

    button {
      width: 100%;
      border: 0;
      padding: 0;
      background-color: transparent;
      display: block;
      cursor: pointer;
      border-radius: $border-radius;
      overflow: hidden;

      &:hover {
        opacity: 0.75;
      }
    }

    picture {
      border-radius: $border-radius;
      overflow: hidden;
    }

    &--list {
      display: flex;

      #{$pager}-item {
        width: 100px;
        padding: 0px 4px;
      }
    }
  }

  &-image {
    position: relative;

    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 2;
      background-image: linear-gradient(
        180deg,
        transparent,
        rgba(var(--primary-color-rgb), 0.6)
      );
    }

    img {
      border-radius: 35px;
    }
  }

  li.gallery-slideshow-toggle {
    .button {
      border: 1px solid var(--N30);
      min-height: 60px;
      color: var(--N500);
      font-weight: 600;

      span {
        vertical-align: middle;
        font-size: 0.9rem;
      }
    }
  }

  .slider {
    background-color: var(--N20);
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
  }

  .slider-arrows-middle {
    .slick-arrow {
      .icon {
        font-size: 30px;
        color: #fff;
      }

      &.slick-prev {
        left: 2.5rem;
      }

      &.slick-next {
        right: 2.5rem;
      }
    }
  }
}

@media #{$media-sm}, #{$media-md} {
  .gallery-slideshow {
    &-pager {
      $pager: &;
      position: relative;
      padding: 0;

      li {
        &.gallery-slideshow-toggle {
          width: 70px;

          .button {
            min-height: 30px;
            span {
              font-size: 0.8rem;
            }
          }
        }

        img {
          height: 50px;
        }
      }

      .slideshow {
        .slick-list {
          padding: 0px 4px !important;
        }
      }

      &--list {
        display: flex;

        #{$pager}-item {
          width: 20%;
          padding: 0px 4px;
        }
      }
    }

    &-image {
      &:before {
        display: none;
      }
    }
  }
}
