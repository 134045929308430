.error {
  position: absolute;
  display: flex;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  gap: 2rem;
  text-align: center;
  transform: translate(-50%, -50%);

  &-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 28px;
  }

  img {
    height: 40px;
    position: absolute;
    bottom: 40px;
  }

  h1 {
    font-size: 2.4vw;
    line-height: 2.4vw;
    font-weight: 800;
    margin: 0;
    color: #1a1a1a;
  }

  h4 {
    font-size: 1.2vw;
    font-weight: 500;
    margin: 0;
    color: #1a1a1a;
  }

  p,
  a {
    font-size: 0.8vw;
    line-height: 18px;
    color: #1a1a1a;
  }
}
