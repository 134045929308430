@mixin spacing($type, $font-size, $height, $border-radius, $padding) {
  .textfield--spacing-#{$type} {
    font-size: $font-size;

    input {
      border-radius: $border-radius;
      height: $height;
      line-height: $height;
      padding: $padding;
    }
  }
}

.textfield {
  $textfield: &;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  input {
    font-size: 1em;
    width: 100%;
    box-sizing: border-box;
    font-weight: 400;
    line-height: 1.15em;
    outline: 0;
    background-color: neutral(0);
    border: 2px solid neutral(30);

    &:not([disabled]):hover {
      background-color: neutral(0);
    }

    &:not([disabled]):focus {
      border-color: neutral(50);
      background-color: neutral(20);
    }
  }
}

@include spacing(strong, 0.9375rem, 44px, 8px, 0 1rem);
@include spacing(default, 0.875rem, 36px, 6px, 0 .7rem);
@include spacing(compact, 0.8125rem, 30px, 4px, 0 .5rem);
