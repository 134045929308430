.brand-card-list {
  margin: 0 -60px;
  .brand-card-item {
    margin-bottom: 75px;
    display: flex;
    flex-direction: column;
    padding: 0 60px;
    .title {
      color: #838383;
      font-size: 30px;
      margin-bottom: 20px;
      line-height: 35px;
      font-weight: bold;
      &.bottom {
        font-weight: 400;
        color: #fff;
      }
    }
    .description {
      color: #ffffff;
      font-size: 15px;
      margin-bottom: 20px;
    }
    .link-wrapper {
      display: flex;
      margin-bottom: 10px;
      justify-content: space-around;
      a {
        // width: 25%;
        font-size: 20px;
        color: #838383;
        text-transform: uppercase;
        font-weight: bold;
        // text-align: center;
        // &:last-child {
        //   text-align: right;
        // }
        // &:first-child {
        //   text-align: left;
        // }
      }
    }
    .image {
      height: 284px;
      width: 100%;
    }
  }
}

@media #{$media-lg} {
  .brand-card-list {
    margin: 0 -25px;
    .brand-card-item {
      padding: 25px;
      .title {
        font-size: 20px;
        margin-bottom: 15px;
      }
      .description {
        font-size: 12px;
      }
      .link-wrapper {
        a {
          font-size: 16px;
        }
      }
      .image {
        height: 200px;
        img {
        }
      }
    }
  }
}
@media #{$media-md} {
}
@media #{$media-sm} {
  .brand-card-list {
    .brand-card-item {
      .title {
        font-size: 23pt;
        margin-bottom: 15px;
      }
      .description {
        font-size: 14pt;
      }
      .link-wrapper {
        a {
          font-size: 11pt;
        }
      }
      .image {
        height: 200px;
        img {
        }
      }
    }
  }
}
@media #{$media-xs} {
  .brand-card-list {
    .brand-card-item {
      margin-bottom: 15px;
      .title {
        font-size: 23px;
        margin-bottom: 15px;
        &.bottom {
          font-size: 20px;
        }
      }
      .description {
        font-size: 14px;
      }
      .link-wrapper {
        a {
          font-size: 11px;
        }
      }
      .image {
        height: 200px;
        img {
        }
      }
    }
  }
}
