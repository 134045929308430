.card {
  position: relative;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: $padding-lg;
  background-color: neutral(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &__aside {
    position: relative;
    box-sizing: border-box;

    .image {
      width: 100%;
    }

    .image-node {
      object-fit: cover;
    }
  }

  &__header {
    position: relative;
    margin-top: $margin;
    padding: 0 $padding-lg;
    box-sizing: border-box;
  }

  &__title {
    color: neutral(800);
    font-size: 1.75rem;
    font-weight: 700;
    margin: $margin-sm 0;
  }

  &__suptitle {
    color: neutral(400);
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }

  &__subtitle {
    color: neutral(600);
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }

  &__body {
    position: relative;
    margin-top: $margin;
    padding: 0 $padding-lg;
    box-sizing: border-box;
  }

  &__footer {
    position: relative;
    margin-top: $margin;
    padding: 0 $padding-lg;
    box-sizing: border-box;
  }
}
