.section-slideshow {
  $root: &;
  transition: all, 0.35s;
  position: relative;
  padding: 2rem 0;
  left: 4rem;
  z-index: 11;

  &--ready {
    left: 0px;

    #{$root}-slides {
      .slideshow {
        left: 0;

        .slick-list {
          transition-delay: 0.1s;

          .slick-slide:nth-child(3) {
            transform: scale(1);
            margin-left: 0;
            transition-delay: 0.1s;
          }
        }

        .slick-arrow {
          &.slick-prev {
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }

    #{$root}-trigger {
      opacity: 0;
      pointer-events: none;
    }

    #{$root}-caption {
      opacity: 0;
      left: -30%;
      pointer-events: none;
    }

    #{$root}-header {
      opacity: 1;
      left: 0;
      transition-delay: 0.2s;
      pointer-events: all;
    }

    #{$root}-container {
      border-radius: 0px;

      &:before {
        background-color: rgba(dark-neutral-rgb(900), 0.75);
        border-radius: 0px;
      }
    }
  }

  &-list {
    .slick-track {
      margin: 0 10%;
    }
  }

  &-container {
    position: relative;
    background-size: cover;
    background-position: -200px bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 2.8rem 0 2.8rem 0;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    transition: all, 0.35s;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: rgba(dark-neutral-rgb(900), 0.25);
      width: 100%;
      height: 100%;
      pointer-events: none;
      transition: all, 0.35s;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
    }

    &:after {
      content: "";
      position: absolute;
      background: dark-neutral(900);
      background: linear-gradient(
        262deg,
        rgba(dark-neutral-rgb(900), 1) 25%,
        rgba(dark-neutral-rgb(900), 0) 100%
      );
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      pointer-events: none;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
    }
  }

  &-header {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 8rem);
    z-index: 11;
    padding: 0 4rem;
    top: 1rem;
    left: 20px;
    transition: all, 0.35s;
    opacity: 0;
    pointer-events: none;

    h4 {
      font-size: 22px;
    }

    &-count {
      display: flex;
      align-items: center;

      strong {
        font-size: 2rem;
        position: relative;
        margin-right: 12px;
        padding-right: 12px;

        &:after {
          position: absolute;
          content: "";
          background-color: rgba($color: #fff, $alpha: 0.8);
          width: 6px;
          height: 6px;
          border-radius: 100%;
          right: -6px;
          top: 50%;
          margin-top: -2px;
        }
      }

      span {
        font-size: 2rem;
        opacity: 0.5;
      }

      button {
        background-color: transparent;
        color: #fff;
        opacity: 1;
        margin-left: 12px;
        cursor: pointer;
        border: none;

        span {
          font-size: 1.4rem;
          opacity: 1;
        }
      }
    }
  }

  &-caption {
    position: absolute;
    height: 100%;
    width: 32%;
    padding: 0 6%;
    left: 0;
    top: 0;
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    z-index: 11;
    transition: all, 0.35s;

    h2 {
      font-size: 4.6vw;

      span {
        display: block;
        color: primary(400);
        line-height: 60px;
      }
    }

    a {
      margin-top: 12px;
    }
  }

  &-trigger {
    position: absolute;
    right: 4rem;
    top: 50%;
    background: black;
    background: -webkit-gradient(
      linear,
      right top,
      left top,
      from(rgba(dark-neutral-rgb(900), 0.6)),
      color-stop(60%, rgba(255, 255, 255, 0))
    );
    border: none;
    height: 100%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 100px;
    color: #fff;

    span {
      font-size: 30px;
    }
  }

  &-close {
    position: absolute;
    left: 0;
    top: 50%;
    background: transparent;
    border: none;
    height: 100%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 92px;
    color: #fff;
    z-index: 2;
    margin-top: 7.5px;

    span {
      font-size: 30px;
    }
  }

  &-slides {
    position: relative;
    z-index: 2;

    .slideshow {
      position: relative;
      left: 32%;
      transition: all, 0.35s;

      .image-slideshow-item {
        overflow: hidden;

        &-with-title {
          border-radius: 50px;

          h4 {
            font-size: 44px;
            bottom: 24px;
            left: 24px;
            text-align: left;
          }

          .image-sustainability {
            right: 16px;
            top: 16px;
          }
        }

        &--rotated {
          .image-slideshow-item {
            &-content {
              padding: 32px;
              width: calc(100% - 64px);

              &:before {
                display: none;
              }
            }
          }

          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 50%;
            bottom: 0;
            left: 0;
            z-index: 0;
            opacity: 0.6;
            background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(black));
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, black 100%);
            border-radius: 8px;
            pointer-events: none;
          }

          img {
            object-fit: cover;
          }

          h4 {
            font-size: 1.8vw;
            left: 0px;
            bottom: 0px;
          }
        }
      }

      .slick-list {
        margin: 0px 200px;
        transition: all, 0.35s;

        .slick-track {
          display: flex;
          margin: 0;
        }

        .slick-slide {
          transition: all, 0.35s;
        }

        .slick-slide:nth-child(3) {
          transform: scale(0.8);
          margin-left: -1.6vw;
        }
      }

      .slick-arrow {
        transition: all, 0.25s;
        height: calc(100% - 16px);
        top: 16px;

        &.slick-disabled {
          display: none !important;
        }

        &.slick-prev {
          opacity: 0;
          background: black;
          background: -webkit-gradient(
            linear,
            left top,
            right top,
            from(rgba(0, 0, 0, 0.6)),
            color-stop(60%, rgba(255, 255, 255, 0))
          );
        }

        &.slick-next {
          opacity: 1;
          background: black;
          background: -webkit-gradient(
            linear,
            right top,
            left top,
            from(rgba(0, 0, 0, 0.6)),
            color-stop(60%, rgba(255, 255, 255, 0))
          );
        }
      }
    }
  }
}

@media #{$media-xl} {
  .section-slideshow {
    &-caption {
      padding: 0 4%;

      h2 {
        font-size: 5vw;
      }
    }

    &-close {
      width: 82px;
    }

    &-slides {
      .slideshow {
        left: 36%;

        .slick-list {
          margin: 0px 100px;

          .slick-slide:nth-child(3) {
            margin-left: -24px;
          }
        }

        .image-slideshow-item {
          overflow: hidden;

          &-with-title {
            border-radius: 50px;

            h4 {
              font-size: 44px;
              bottom: 24px;
              left: 24px;
            }

            .image-sustainability {
              right: 16px;
              top: 16px;
            }
          }
        }

        .image-slideshow-item {
          &-with-title {
            h4 {
              font-size: 36px;
            }
          }

          &--rotated {
            .image-slideshow-item-content {
              padding: 16px 24px;
              width: calc(100% - 48px);
            }

            h4 {
              font-size: 28px;
              left: 0;
            }
          }
        }
      }
    }
  }
}

@media #{$media-lg} {
  .section-slideshow {
    &-caption {
      padding: 0 2rem;

      h2 {
        font-size: 3.2rem;
        line-height: 3.2rem;
      }
    }

    &-close {
      width: 72px;
    }

    &-slides {
      .slideshow {
        .image-slideshow-item {
          &-with-title {
            h4 {
              font-size: 38px;
              bottom: 24px;
              left: 24px;
            }
          }

          &--rotated {
            .image-slideshow-item-content {
              padding: 16px 24px;
              width: calc(100% - 48px);
            }

            h4 {
              font-size: 30px;
              left: 0px;
            }
          }
        }
      }
    }
  }
}

@media #{$media-md} {
  .section-slideshow {
    left: 0;
    padding: 1.2rem;

    &-caption {
      position: relative;
      width: calc(100% - 4rem);

      h2 {
        font-size: 2.8rem;
        line-height: 2rem;
        font-weight: 800;
        margin-bottom: 0px;
      }

      a {
        border-radius: 30px;
      }
    }

    &-list {
      .slick-track {
        margin: 0 24px;
        width: 100% !important;
        justify-content: center;
        align-items: center;
      }
    }

    &-header {
      opacity: 1;
      left: inherit;
      padding: 0;
      right: 28px;
      bottom: 28px;
      top: inherit;
      pointer-events: none;
      justify-content: flex-end;

      h4 {
        display: none;
      }

      &-count {
        button {
          display: none;
        }

        strong,
        span {
          font-size: 1.4rem;
          padding-right: 8px;
        }
      }
    }

    &-trigger,
    &-close {
      display: none;
    }

    &-container {
      border-radius: 50px;
      background-attachment: initial !important;

      &:after {
        display: none;
      }

      &:before {
        border-radius: 50px;
        background-color: rgba(primary-rgb(400), 0.7);
      }
    }

    &-slides {
      .slideshow {
        left: -1.2rem;
        width: calc(100% + 2.4rem);

        .slick-list {
          margin: 0px 20px;

          .slick-slide {
            padding: 0px 4px;

            &:nth-child(3) {
              margin: 0;
              transform: scale(1);
            }
          }
        }

        .slick-arrow {
          width: 40px;

          &.slick-prev {
            opacity: 1;
          }
        }

        .image-slideshow-item {
          &-with-title {
            border-radius: 25px;

            h4 {
              font-size: 32px;
              bottom: 18px;
              left: 18px;
            }
          }

          &--rotated {
            .image-slideshow-item-content {
              padding: 16px 24px;
              width: calc(100% - 48px);

              &:before {
                border-radius: 25px;
              }
            }

            h4 {
              font-size: 28px;
              left: 0;
            }
          }
        }
      }
    }
  }
}

@media #{$media-xs} {
  .section-slideshow {
    &-list {
      .slick-track {
        margin: 0;
      }
    }

    &-caption {
      h2 {
        font-size: 2.35rem;

        span {
          line-height: 110%;
        }
      }
    }

    &-slides {
      .slideshow {
        .image-slideshow-item {
          &-with-title {
            h4 {
              font-size: 22px;
              bottom: 12px;
              left: 12px;
            }
          }

          &--rotated {
            .image-slideshow-item-content {
              padding: 8px;
              width: calc(100% - 16px);

              &:before {
                border-radius: 25px;
              }
            }

            h4 {
              font-size: 20px;
              left: 0;
              line-height: 104%;
              padding: 0px 8px;
            }
          }
        }
      }
    }
  }
}

@media #{$media-es} {
  .section-slideshow {
    &-list {
      .slick-track {
        margin: 0;
      }
    }

    &-caption {
      h2 {
        font-size: 2.2rem;
      }
    }

    &-slides {
      .slideshow {
        .image-slideshow-item {
          &-with-title {
            h4 {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
