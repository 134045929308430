.organization-items {
  margin: 0 auto;
  width: max-content;
  position: relative;
  &::before {
    content: '';
    height: calc(100% - 89px);
    width: 1px;
    background-color: #838383;
    position: absolute;
    top: 44.5px;
  }
  &-item {
    align-items: center;
    margin: 80px 0;
    position: relative;
    padding-left: 100px;
    &-detail {
      border-radius: 30px;
      background-color: #1d1d1d;
      padding: 15px;
      display: flex;
      align-items: center;
      width: 400px;
      img {
        border-radius: 15px;
        width: 60px;
        height: 60px;
        object-fit: cover;
      }
      &-desc {
        padding: 0 15px;
        &-title {
          font-size: 20px;
        }
        &-department {
          font-size: 13px;
          color: #838383;
        }
      }
      &::before {
        content: '';
        height: 1px;
        width: 100px;
        background-color: #838383;
        position: absolute;
        left: 0;
      }
    }
    .organization-items {
      &::before {
        display: none;
      }
    }
    &.has-sector {
      padding-left: 100px;
      .organization-items {
        padding: 25px 0 0 100px;
        margin: 0;
        left: 25px;
        &::before {
          display: block;
          height: calc(100% - 44.5px);
          top: 0;
          left: 0;
        }
        &-item {
          padding: 0;
          margin: 30px 0;
          &-detail {
            &::before {
              width: 200px;
              left: -100px;
            }
          }
          .organization-items {
            &-item {
              &-detail {
                &::before {
                  width: 100px;
                }
              }
            }
          }
        }
      }
    }
    &.has-manager {
      &:last-child {
        margin-bottom: 200px;
        .organization-items {
          position: absolute;
          left: 125px;
          &:before {
            height: calc(100% - 93.5px);
          }
        }
      }
      .has-manager {
        .organization-items {
          left: 30px;
          &::before {
            height: calc(100% - 75px);
          }
          .organization-items-item {
            .organization-items-sector-title {
              display: none;
            }
          }
        }
      }
    }
  }
  &-sector-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 15px;
  }
}

@media #{$media-lg} {
  .organization-items {
    margin: 25px auto;
    box-sizing: border-box;
    &::before {
      top: 35px;
      height: calc(100% - 82px);
    }
    &-item {
      padding-left: 15px;
      margin: 15px 0;
      &-detail {
        width: fit-content !important;
        max-width: 210px;
      }
      .organization-items {
        margin: 15px 0;
        &-item {
          &-detail {
            width: max-content;
            border-radius: 10px;
            padding: 10px 15px;
            .image {
              min-width: 50px;
              min-height: 50px;
              img {
                width: 50px;
                height: 50px;
                border-radius: 10px;
              }
            }
            &-desc {
              width: calc(100% - 50px);
              padding: 0 15px;
              &-title {
                font-size: 18px;
              }
              &-department {
                font-size: 14px;
              }
            }
            &::before {
              width: 15px;
            }
          }
          &.has-sector {
            padding-left: 15px;
            .organization-items-sector-title {
              font-size: 20px;
              margin: 15px 0;
            }
            .organization-items {
              padding: 15px 0 0 15px;
              left: 15px;
              &::before {
                height: calc(100% - 42.5px);
              }
              &-item {
                margin: 15px 0;
                &:first-child {
                  margin-top: 0;
                }
                &-detail {
                  &::before {
                    width: 30px;
                    left: -15px;
                  }
                }
                .organization-items {
                  &-item {
                    &-detail {
                      max-width: 230px;
                      &::before {
                        width: 15px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media-md} {
  .organization-items {
    &-item {
      &-detail {
        width: 265px;
      }
    }
  }
}

@media #{$media-sm} {
  .organization-items {
    &-item {
      &-detail {
        &-desc {
          &-title {
            font-size: 13px !important;
          }
          &-department {
            font-size: 10px !important;
          }
        }
      }
      .organization-items {
        &-item {
          &.has-sector {
            .organization-items-sector-title {
              max-width: 300px;
              font-size: 17px;
            }
          }
          &.has-manager {
            &:last-child {
              margin-bottom: 100px;
              .organization-items {
                left: 25px;
              }
            }
            .has-manager {
              .organization-items {
                left: 10px;
                .organization-items-item {
                  padding: 0;
                }
                &::before {
                  height: calc(100% - 56.5px);
                }
              }
            }
          }
        }
      }
    }
  }

  .organization-items {
    &-item {
      &-detail {
        max-width: 180px !important;
      }
    }
  }
}
