@use 'sass:math';

@mixin gutter($name, $space) {
  .slider--gutter-#{$name} {
    margin-left: -$space;
    margin-right: -$space;

    .slick-slide {
      padding-left: $space;
      padding-right: $space;
    }
  }
}

.slider {
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    box-sizing: border-box;

    & > div {
      font-size: 0;
      height: 100%;
    }
  }

  .slick-arrow {
    position: absolute;
    z-index: 100;
    transform: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    box-sizing: border-box;
    outline: none;
    background-color: transparent;

    &.slick-disabled {
      opacity: 0.5;
    }
  }

  .image {
    width: 100%;

    .image-node {
      object-fit: cover;
    }
  }

  &--arrow {
    &-top {
      margin-top: 40px;

      .slick-arrow {
        padding: $padding-xs;
        border-radius: 100%;
        bottom: calc(100% + 8px);
        background-color: neutral(20);
      }

      .slick-prev {
        right: 40px;
      }

      .slick-next {
        right: 0;
      }
    }

    &-middle {
      .slick-arrow {
        color: neutral(0);
        top: 50%;
        transform: translateY(-50%);
        transition: all, 0.35s;
        padding: $padding-sm;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }

      .slick-prev {
        left: 2rem;
      }

      .slick-next {
        right: 2rem;
      }
    }

    &-bottom {
      margin-bottom: 40px;

      .slick-arrow {
        padding: $padding-xs;
        border-radius: 100%;
        top: calc(100% + 8px);
        background-color: neutral(20);
      }

      .slick-prev {
        right: 40px;
      }

      .slick-next {
        right: 0;
      }
    }
  }
}

@include gutter(xlarge, math.div($margin-xl, 2));
@include gutter(large, math.div($margin-lg, 2));
@include gutter(medium, math.div($margin-md, 2));
@include gutter(small, math.div($margin-sm, 2));
@include gutter(xsmall, math.div($margin-xs, 2));
@include gutter(none, $margin-none);
