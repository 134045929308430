.custom-select {
  position: relative;

  &-option {
    position: relative;
    font-weight: 500;
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.9);
    display: block;
    padding-left: 30px;
    line-height: 34px;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  &-selected {
    font-weight: 600;
    color: #fff;
  }

  &-container {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: transparent;
    border: none;
    color: #fff;
  }

  &-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 150px;
    max-width: 300px;
    z-index: 100;
    padding: 1rem 0;
    background-color: var(--secondary-color);

    ul {
      display: block;
      padding: 0;
    }

    li {
      color: var(--text-color);
      cursor: pointer;
      font-size: 15px;
      font-weight: 400;
      display: block;
      white-space: nowrap;
      padding: 2px 1rem 2px 32px;

      button {
        background: transparent;
        border: none;
        color: var(--text-color);
      }

      &:hover {
        color: var(--text-color);
      }
    }
  }

  .c-button.c-button--spacing-compact {
    padding: 0 8px;
    background-color: transparent;
  }

  .button-group {
    align-items: center;
  }
}
