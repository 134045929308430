.page-header {
  position: relative;
  width: 100%;
  min-height: 400px;
  height: 50vh;
  margin-bottom: -10vh;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;

  & > div {
    height: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, dark-neutral-rgb(900) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, dark-neutral-rgb(900) 100%);
  }

  &-caption {
    position: absolute;
    z-index: 2;
    bottom: 10vh;

    h1 {
      span {
        -ievar-color: primary(400);
        color: primary(400);
      }
    }

    p {
      max-width: 800px;
      font-weight: 500;
      margin: 1em 0 0 0;
    }
  }
}

@media #{$media-xs} {
  .page-header {
    min-height: 200px;
    height: 20vh;
    margin-bottom: -5vh;

    &-caption {
      bottom: 5vh;
    }
  }
}
