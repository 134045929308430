$color-brand-1: var(--primary-color);
$color-brand-2: #408ec8;
$color-brand-3: #449143;
$color-white: #fff;
$color-text: var(--text-color);
$area-MB: 8px;
$button-1-bgc: $color-text;
$button-2-bgc: $color-brand-2;
$title-icon-W: 80px;

.facility-list {
  margin-top: 2rem;
}

.green-facilities-header {
  position: relative;
  width: 100%;
  min-height: 600px;
  height: 50vh;
  // background-image: url(https://cdn.goturkiye.com/sustainable/green-tesisler-desktop-kapak.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      $color-brand-1 100%
    );
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0)),
      to($color-brand-1)
    );
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      $color-brand-1 100%
    );
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      $color-brand-1 100%
    );
  }
}

// .page-header-caption {}

.page-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 50px;
  font-weight: 500;
  line-height: 1;
}

.green-logo {
  margin: 0 10px;
  width: 150px !important;
  height: auto;
}

.page-sub-title {
  margin-top: -40px;
  font-size: 30px;
  font-weight: 400;
}

// .facility-list {}

.facility {
  &,
  * {
    box-sizing: border-box;
  }
  display: flex;
  flex-direction: row;
  width: 100%;

  &-card {
    margin: 8px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .type {
      margin-bottom: 4px;
      padding-right: $title-icon-W + 6px;

      ol,
      ul {
        display: flex;
        flex-direction: row;
        padding: 0;
        list-style: none;

        li {
          margin: 0 2px 0 0;
        }
      }

      // svg {}

      @for $i from 1 through 5 {
        .star-#{$i} {
          li:nth-child(-n + #{$i}) {
            svg path {
              fill: dark-neutral(0);
            }
          }
        }
      }
    }

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      margin: 0 0 $area-MB 0;
      padding-right: $title-icon-W + 6px;
      width: 100%;
      font-size: 26px;
      font-weight: 700;
      text-transform: capitalize;

      .lazy-container {
        position: absolute;
        right: 0;
        top: -30px;
        width: 70px !important;
        height: auto;
      }
    }
  }

  &-not-found {
    display: inline-flex;
    padding: 12px 20px;
    background-color: rgb(150, 150, 150, 0.1);
    border-radius: 16px;
  }

  &-list {
    &-count {
      background-color: rgb(150, 150, 150, 0.1);
      padding: 8px 16px;
      border-radius: 12px;
      margin-right: 8px;
      font-size: 0.8rem;
      display: flex;
      align-items: center;

      strong {
        padding-right: 4px;
      }
    }

    &-pagination {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0.8rem 0.4rem;

      .pagination:not(:first-child) {
        margin-top: 0;
      }

      .c-button {
        border-radius: 16px;
      }

      .c-button.c-button--appearance-light {
        background-color: rgb(255, 255, 255, 0.1);
        color: #fff;
      }
    }
  }

  &-filter {
    margin-bottom: 0.8rem;

    &-field {
      margin-right: 20px;

      strong {
        margin-right: 12px;
      }

      select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border: none;
        border-radius: 0px;
        padding: 8px 40px 8px 24px;
        border-radius: 24px;
        font-weight: 600;
        font-size: 14px;
        color: var(--primary-color);
        background: url('/icons/angle-down.png') 0 0 no-repeat;
        background-size: 10px;
        background-position: calc(100% - 16px) center;
        background-color: #fff;
        min-width: 128px;
      }

      option {
        padding: 0px;
      }
    }
  }

  .image {
    width: 240px;
    overflow: hidden;

    img {
      object-fit: cover;
      width: auto;
      min-height: 240px;
      border-radius: 50px;
      background-color: $color-white;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0 10px 30px;
    width: 70%;
  }

  .city {
    margin: 0 0 $area-MB 0;
    font-size: 16px;
    font-weight: 500;
  }

  .certificate {
    margin: 0 0 $area-MB * 3 0;
  }

  .certificate-firm {
    display: flex;
    justify-items: flex-start;
    align-items: center;
    margin: 0 0 $area-MB 0;
    font-size: 16px;
    font-weight: 500;

    img {
      margin: 0 4px 0 0;
      width: 26px !important;
      height: auto;
    }
  }

  .certificate-date {
    margin: 0 0 $area-MB 0;
    font-size: 16px;
    font-weight: 500;
  }

  &-button-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    justify-self: flex-end;
    margin-top: 12px;

    a {
      margin: 0 8px 8px 0;
      padding: 8px 16px;
      text-decoration: none;
      text-transform: capitalize;
      border-radius: 15px;
    }

    .web-button {
      color: dark-neutral(900);
      background-color: dark-neutral(0);

      &:hover {
        background-color: dark-neutral(50);
      }
    }

    .see-button {
      color: $color-text;
      background-color: $button-2-bgc;

      &:hover {
        background-color: darken($button-2-bgc, 5%);
      }
    }
  }
}

.filter {
  margin-bottom: 0.8rem;
  z-index: 100;

  &-container {
    max-width: 50%;
  }

  .custom-select {
    &-container {
      border-radius: 16px;
      background-color: rgba(150, 150, 150, 0.1);
      backdrop-filter: blur(8px);
      padding: 6px 16px;
      transition: all, 0.4s;
      cursor: pointer;

      &:hover,
      &:visited,
      &:focus {
        background-color: rgb(150, 150, 150, 0.15);
      }
    }

    &-option {
      padding-left: 16px;
      font-size: 1rem;
    }

    &-dropdown {
      background-color: rgb(26, 26, 26, 90%);
      min-width: 200px;
      backdrop-filter: blur(8px);
      margin-top: 8px;
      border-radius: 16px;
      padding-right: 8px;

      .scrollbar {
        max-height: 300px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  &-search {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 16px;
    background-color: rgba(150, 150, 150, 0.1);
    backdrop-filter: blur(8px);
    padding: 6px 16px;
    transition: all, 0.4s;
    box-sizing: border-box;

    &:hover,
    &:visited,
    &:focus {
      background-color: rgb(160, 160, 160, 0.15);
    }

    input {
      flex: 1;
      border: 0;
      font-family: 'Red Hat Display', sans-serif;
      font-weight: normal;
      font-size: 1rem;
      outline: 0;
      height: 34px;
      background-color: transparent;
      box-sizing: border-box;
      padding-left: 16px;
      color: var(--text-color);
      width: 100%;

      &::-webkit-input-placeholder {
        color: var(--text-color);
        font-weight: 500;
      }
    }

    button {
      font-weight: 400;
      font-size: 13px;
      text-align: left;
      border: 0;
      cursor: pointer;
      margin: 0 0 0 8px;
      height: 34px;
      background-color: transparent;
    }
  }
}

@media #{$media-lg} {
  $title-icon-W: 70px;

  .facility {
    .title {
      font-size: 28px;

      .lazy-container {
        width: $title-icon-W !important;
      }
    }
  }
}

@media #{$media-md} {
  .filter {
    &-container {
      max-width: calc(100% + 1rem);

      & > div {
        margin-bottom: 0.4rem;
      }
    }

    &-search {
      input {
        padding: 0 0 0 16px;
      }
    }
  }
}

@media #{$media-sm} {
  .page-sub-title {
    margin-top: -20px;
  }

  .green-facilities-header {
    .lazy-container {
      overflow: inherit !important;
    }
  }

  .green-logo {
    width: 80px !important;
  }

  .facility {
    &-card {
      margin: 0px;

      .title {
        font-size: 20px;
        padding: 0;
        width: calc(100% - 60px);

        .lazy-container {
          width: 50px !important;
          top: 0px;
          right: -60px;
        }
      }

      .type {
        margin-bottom: 12px;
        width: calc(100% - 60px);
      }
    }

    &-list {
      &-count {
        margin-bottom: 12px;
      }

      &-pagination {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .image,
    .content {
      width: 100%;
    }

    .image {
      max-width: 40%;

      img {
        border-radius: 17px;
        min-height: auto;
      }
    }

    .content {
      padding: 12px 10px;
    }

    .certificate {
      margin-bottom: 0;
    }

    &-button-list {
      margin-top: 8px;
    }
  }
}

@media #{$media-es} {
  .facility {
    .content {
      padding: 4px 10px;
    }
  }
}

.facility-filter-field {
  option {
    text-transform: capitalize;
  }
}
