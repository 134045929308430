.main-slideshow {
  position: sticky;
  width: 100vw;
  height: 100vh;
  top: 0;
  border-radius: 0px;
  pointer-events: all;
  overflow: hidden;
  z-index: 2;
  transition: all, 0.1s;

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
  }

  .slick-arrow {
    position: absolute;
    left: 50%;
    bottom: 2.8rem;
    z-index: 200;
    padding: 8px;
    border: 0;
    color: var(--text);
    cursor: pointer;
    outline: none;
    transform: translateY(-50%);
    background-color: transparent;

    &.slick-prev {
      margin-left: calc(-50% + 90px);
    }

    &.slick-next {
      margin-left: calc(-50% + 120px);
    }

    .icon {
      font-size: 1.5rem;
    }
  }

  .slick-slide > div {
    width: 100%;
    height: 100%;
  }

  .container-extended {
    position: relative;
    z-index: 10;
    display: flex;
    height: 100%;
    align-content: center;
  }

  &-screen {
    position: relative;
    height: 100%;
    text-decoration: none;

    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      display: block;
      z-index: 2;
      background: rgb(0, 0, 0);
      background: linear-gradient(90deg, var(--dark) 0%, transparent 100%);
      pointer-events: none;
    }
  }

  &-backdrop {
    top: 0;
    left: 0;
    z-index: 1;

    img {
      object-fit: cover;
    }
  }

  &-video {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &-poster {
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: all, 0.4s;
      object-fit: cover;
      z-index: 2;
      opacity: 1;
      pointer-events: none;

      &--fade {
        opacity: 0;
        transition-delay: 0.3s;
      }
    }

    video {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &-caption {
    position: absolute;
    width: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 100;
    bottom: 10%;
    z-index: 4;

    &-wrapper {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      z-index: 4;
    }

    h1,
    h4 {
      color: #fff;
      margin: 0;
    }

    h1 {
      line-height: 5.4rem;
      margin-top: 20px;
    }

    h4 {
      line-height: 2rem;
    }

    button,
    a,
    &-footer {
      margin-top: 2.4rem;
      max-width: max-content;
    }
  }

  &-tools {
    margin-bottom: 1.4rem;
    z-index: 4;
  }

  &-count {
    display: flex;
    align-items: center;

    strong {
      font-size: 1.6rem;
      position: relative;
      margin-right: 8px;
      padding-right: 8px;

      &:after {
        position: absolute;
        content: "";
        background-color: rgba($color: #fff, $alpha: 0.6);
        width: 4px;
        height: 4px;
        border-radius: 100%;
        right: -2px;
        top: 50%;
        margin-top: -2px;
      }
    }
  }

  &-next {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-left: 4px;
    z-index: 4;
  }

  &-dots {
    display: flex;
    align-items: center;
    z-index: 4;
  }

  &-dot {
    display: block;
    height: 5px;
    width: 20px !important;
    border-radius: 8px;
    border: none;
    max-width: 40px !important;
    background-color: rgba($color: #fff, $alpha: 0.6) !important;
    margin-right: 4px;
    transition: all, 0.25s;
    -moz-transition: all, 0.25s;
    -o-transition: all, 0.25s;
    cursor: pointer;
    content: "33";

    &--active {
      width: 40px !important;
      background-color: #fff !important;
    }
  }
}

@media #{$media-lg} {
  .main-slideshow {
    &-video {
      object-position: 0px;
    }
  }
}

@media #{$media-sm} {
  .main-slideshow {
    $root: &;

    &-caption {
      &-wrapper {
        width: 100%;
        max-width: 100%;

        h4 {
          font-size: 2.8rem;
          line-height: 2.4rem;
        }

        h1 {
          font-size: 4.8rem;
          line-height: 3.8rem;
          margin: 20px 0px;
        }
      }
    }
  }
}

@media #{$media-xs} {
  .main-slideshow {
    $root: &;
    max-height: -webkit-fill-available;

    &-video,
    &-backdrop {
      object-position: inherit;
    }

    .slick-arrow {
      display: none !important;
    }

    &-count {
      strong {
        font-size: 1.2rem;
      }

      span {
        font-size: 0.8rem;
      }
    }

    &-dot {
      width: 8px;

      &--active {
        width: 16px;
      }
    }

    &-next {
      span {
        font-size: 16px;
      }
    }

    &-caption {
      left: 0;
      bottom: 80px;
      text-align: center;
      transform: none;
      max-width: 100%;
      padding: 0 0.4rem;

      &-wrapper {
        width: 100%;
        max-width: 100%;
        text-align: center;
        align-items: center;

        h4 {
          font-size: 2.2rem;
          line-height: 1.8rem;
        }

        h1 {
          font-size: 3.2rem;
          line-height: 2.8rem;
          margin: 12px 0px 20px 0px;
        }
      }

      button,
      a {
        min-width: 100px;
        border-radius: 24px;
      }

      &-footer {
        margin-top: 1.6rem;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        max-width: 100%;
        width: 100%;

        a {
          span {
            font-size: 18px;
          }
        }

        #{$root}-tools {
          margin-top: -0.4rem;
          margin-bottom: 0px;
          padding-right: 0.8rem;
        }
      }

      button,
      a {
        margin-top: 1.2rem;
      }
    }
  }
}
