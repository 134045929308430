@include theme(light) {
  --N0: #{light-neutral(0)};
  --N10: #{light-neutral(10)};
  --N20: #{light-neutral(20)};
  --N30: #{light-neutral(30)};
  --N40: #{light-neutral(40)};
  --N50: #{light-neutral(50)};
  --N60: #{light-neutral(60)};
  --N70: #{light-neutral(70)};
  --N80: #{light-neutral(80)};
  --N90: #{light-neutral(90)};
  --N100: #{light-neutral(100)};
  --N200: #{light-neutral(200)};
  --N300: #{light-neutral(300)};
  --N400: #{light-neutral(400)};
  --N500: #{light-neutral(500)};
  --N600: #{light-neutral(600)};
  --N700: #{light-neutral(700)};
  --N800: #{light-neutral(800)};
  --N900: #{light-neutral(900)};
}

@include theme(light) {
  --primary-50: #feece5;
  --primary-75: #f9cebd;
  --primary-100: #ffb99d;
  --primary-300: #ff672a;
  --primary-400: #f34800;
  --primary-500: #bd3700;
}
