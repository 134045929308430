.section {
  width: 100%;
  margin-top: 3rem;
  z-index: 1;
  position: relative;

  &-header {
    width: 100%;
    margin-bottom: 3rem;

    &-title {
      margin: 0;
      letter-spacing: -1px;
      line-height: 1;

      span {
        position: relative;
        -ievar-color: primary(400);
        color: primary(400);
        overflow: hidden;
      }
    }

    &-description {
      max-width: 800px;
      font-weight: 500;
      margin: 1em 0 0 0;
    }

    a {
      margin-top: 0.8rem;
    }
  }
}

@media #{$media-sm} {
  .section-header {
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem;

    a {
      margin-top: 0.8rem;
      font-size: 0.8rem;
    }
  }
}
