@mixin size($type, $font-size) {
  .c-icon--size-#{$type} {
    font-size: $font-size;
  }
}

.c-icon {
  fill: currentcolor;
  color: currentcolor;
  display: inline-block;
  vertical-align: middle;
  flex-shrink: 0;
  line-height: 1;
}

.c-icon-list {
  display: flex;

  &-vertical {
    flex-direction: column;

    & > *:not(:first-of-type) {
      margin-top: 0.5rem;
    }
  }

  &-horizontal > *:not(:first-of-type) {
    margin-left: 1rem;
  }

  &-item {
    -ievar-color: dark-neutral(0);
    color: dark-neutral(0);
    display: inline-flex;
    align-items: center;

    span {
      display: block;
    }
  }

  &-item-anchor {
    -ievar-color: dark-neutral(0);
    color: dark-neutral(0);
    text-decoration: none;
    display: inline-flex;
    align-items: center;

    &:hover {
      opacity: 0.85;
    }

    span {
      display: block;
    }
  }

  &-item-label {
    flex: 1;
    padding-left: 0.5rem;
  }
}

@include size(xsmall, 14px);
@include size(small, 16px);
@include size(medium, 22px);
@include size(large, 30px);
@include size(xlarge, 44px);
