.asg-header {
  $root: &;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  padding: 31px 0px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  // -ievar-background-color: light-neutral(0);
  // background-color: rgba($color: #000000, $alpha: 0.6);
  border-bottom: 1px solid rgb(dark-neutral-rgb(0), 0.6);
  background: rgba($color: dark-neutral-rgb(0), $alpha: 0.4);
  transition: all, 0.4s;

  &.header-scrolled {
    padding: 10px 0;
    background: rgba($color: dark-neutral-rgb(0), $alpha: 0.8);
    border-bottom: 1px solid rgb(dark-neutral-rgb(0), 1);
    transition: all, 0.4s;
  }

  &.fixed {
    position: fixed;
  }

  .row {
    &-align-center {
      align-items: center;
    }
    &-justify-between {
      justify-content: space-between;
    }
  }

  .col {
    flex: 0 0 auto;
    width: auto;
    &.width-auto {
      flex: 1 0 0%;
    }
  }

  ul.desktop-menu {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    & > li {
      margin: 0;
      text-align: center;
      position: relative;
      padding: 0 15px;
      width: -webkit-fill-available;
      & > a {
        font-size: 17px;
        color: #fff;
        position: relative;
        z-index: 3;
        display: block;
        padding: 20px 0;
        margin: 0;
      }
      .sub-menu {
        position: absolute;
        opacity: 0;
        pointer-events: none;
        list-style: none;
        padding: 85px 0 20px;
        width: 100%;
        left: 0;
        top: 0;
        border-radius: 39px;
        z-index: 1;
        background-color: #000;
        margin: 0 !important;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.2);
          filter: blur(4px);
          -o-filter: blur(4px);
          -ms-filter: blur(4px);
          -moz-filter: blur(4px);
          -webkit-filter: blur(4px);
          border-radius: 39px;
          z-index: -1;
        }
        a {
          font-size: 14px;
          color: #fff;
          text-align: center;
          padding: 0;
          display: block;
          max-height: 0;
          overflow: hidden;
          transition: all, 1s;
        }
      }

      &:hover {
        & > a {
          border-bottom: 1px solid #fff;
        }
        .sub-menu {
          opacity: 1;
          pointer-events: auto;
          li {
            a {
              max-height: 100px;
              padding: 5px 0;
              transition: all, 1s;
            }
          }
        }
      }
    }
  }

  &.header-reverse {
    border-bottom: 1px solid rgb(light-neutral-rgb(0), 0.6);
    background: rgba($color: light-neutral-rgb(0), $alpha: 0.4);
    ul.desktop-menu {
      & > li {
        .sub-menu {
          a {
            color: dark-neutral(0);
          }
        }
        & > a {
          color: dark-neutral(0);
          border-color: dark-neutral(0);
        }
      }
    }
    .asg-header-language-selection-current {
      button {
        color: dark-neutral(0);
      }
    }
    .asg-header-menu-button {
      color: dark-neutral(0) !important;
    }
  }

  &--with-panel {
    &#{$root}--blurred {
      transition: 0s;
      backdrop-filter: inherit !important;
    }
  }

  &-logo {
    width: auto;
    display: inline-flex;
    vertical-align: top;
    cursor: pointer;

    img {
      height: 70px;
      display: block;
    }
  }

  &-menu-anchor {
    color: primary(300);
    font-size: 14px;
    font-weight: 500;
    padding: 6px 16px;
    border-radius: 16px;
    letter-spacing: 0.2px;
    text-decoration: none;
    margin-right: 8px;
    border: 1px solid primary(300);
    transition: all, 0.45s;

    &:hover {
      border-color: primary(300);
      background-color: primary(300);
      color: #fff !important;
    }
  }

  &-menu-button {
    color: #fff;
    display: inline-flex;
    font-size: 14px;
    align-items: center;
    vertical-align: middle;
    background-color: transparent;
    outline: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    margin-right: 16px;
  }

  &-language-selection {
    position: relative;
    display: inline-flex;

    &-current {
      font-size: 15px;
      font-weight: 500;
      text-transform: uppercase;
      cursor: pointer;
      // padding-right: 18px;
      margin: 0;
      display: block;
      opacity: 0.7;
      color: #fff;
      align-items: center;
      button {
        background: none !important;
        border: none;
        color: light-neutral(0);
        box-shadow: none;
        text-transform: uppercase;
        &:focus,
        &:active,
        &:hover {
          background: none !important;
          border: none !important;
          box-shadow: none !important;
        }
      }
      .icon {
        position: absolute;
        top: 50%;
        right: 8px;
        font-size: 12px;
        transform: translateY(-50%);
      }
    }

    &-dropdown {
      position: absolute;
      top: 100%;
      left: 50%;
      min-width: 100px;
      transform: translateX(-50%);
      padding-top: 7px;

      &-list {
        position: relative;
        margin: 0;
        padding: 10px 0;
        list-style: none;
        background-color: rgba($color: #080808, $alpha: 0.91);
        border-radius: 8px 8px;

        // &:before {
        //   position: absolute;
        //   content: '';
        //   left: 50%;
        //   bottom: 100%;
        //   width: 0;
        //   height: 0;
        //   border: 10px solid transparent;
        //   border-bottom-color: #fff;
        //   transform: translateX(-50%);
        // }

        li {
          text-align: center;
          cursor: pointer;
          padding: 0px 8px;
          font-size: 14px;
          margin: 0px;

          button {
            background-color: transparent;
            padding: 0px;
            line-height: 20px;
            color: #fff;
            &:not([disabled]):hover,
            &:not([disabled]):focus {
              background-color: transparent;
              color: #fff;
              outline: none;
              box-shadow: inherit;
            }
          }

          &:hover {
            // background-color: #fff;
          }
        }
      }
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-theme {
    display: inline-flex;
    align-items: center;
    margin-right: 16px;

    &-text {
      font-size: 0.9rem;
      margin-right: 8px;
    }

    &-selector {
      display: flex;
      align-items: center;
      height: 28px;
      overflow: hidden;

      &-item {
        $item: &;
        display: flex;
        align-items: center;
        border: none;
        background: transparent;
        cursor: pointer;

        &-image {
          display: flex;
          align-items: center;
          border-radius: 100%;
        }

        img {
          height: 24px;
        }
      }
    }
  }
}

.navigation {
  position: relative;
  max-width: 560px;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &-menu {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 250ms;
    opacity: 0;

    & > ul {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        & > a {
          font-size: 18px;
          text-align: center;
          color: #fff;
          font-weight: 500;
        }
        & > button {
          span {
            font-size: 18px;
            color: #fff;
          }
        }
        &.hasSubMenu {
          background: #1d1d1d;
          border-radius: 23pt;
          padding: 30px;
          & > a,
          button {
            border-bottom: 1px solid #696969;
            text-align: center;
            padding-bottom: 15px;
            margin-bottom: 10px;
            color: #fff;
            font-weight: bold;
          }

          ul {
            margin: 0;
            padding: 0;
            li {
              margin-bottom: 10px;
              a {
                font-size: 14pt;
                text-align: center;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -ievar-background-color: #000;
  background-color: #000;
  z-index: 990;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &--hide {
    opacity: 0;
    pointer-events: none;
  }

  &--show {
    opacity: 1;
    pointer-events: all;
    padding: 15px 0;
  }

  &-logo {
    transition: all, 0.25s;
    cursor: pointer;
  }

  &-close-button {
    color: #fff;
    display: inline-flex;
    font-size: 14px;
    align-items: center;
    vertical-align: middle;
    background-color: transparent;
    outline: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    margin-right: 16px;
  }
}

@media screen and (max-width: 1500px) {
  .asg-header {
    ul.desktop-menu {
      & > li {
        & > a {
          font-size: 15px;
        }
        ul {
          li {
            a {
              font-size: 13px !important;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1350px) {
  .asg-header {
    padding: 30px 30px;
    .asg-header-logo img {
      height: 50px;
    }
    ul.desktop-menu {
      li {
        min-width: auto;
        a {
          font-size: 16px;
        }
        .sub-menu {
          a {
            font-size: 12px;
          }
        }
      }
    }
    .asg-header-actions {
      margin-top: 10px;
    }
  }
}

@media #{$media-sm} {
  .asg-header {
    padding: 10px 0;
    .asg-header-actions {
      margin-top: 3px;
    }
    .asg-header-language-selection-current {
      font-size: 13px;
    }
    .asg-header-panel-right {
      margin-top: 3px;
      display: flex;
      align-items: center;
    }
  }
}
